/** 市场统计 */
<template>
  <div class="content">
    <!-- 数据总括 -->
    <DataBlanket />
    <!-- 搜索 -->
    <section class="market-search back-fff border-r4 align-center">
      <label v-if="$isPc" for="marketSearch">查询</label>
      <div class="market-search-inp">
        <el-input id="marketSearch" :class="{'inp-error':isSearchError}" v-model="searchVal"
          @input="isSearchError=false" @change="changeAll" @keyup.enter.native="searchBtn" placeholder="请输入关键词搜索">
          <el-button slot="suffix" icon="el-icon-search" @click="searchBtn"></el-button>
        </el-input>
      </div>
    </section>
    <!-- 表格 -->
    <section class="mb-30">
      <!-- PC端显示 -->
      <el-table v-if="$isPc" :data="tableData" border stripe :fit="true" style="width: 100%">
        <el-table-column prop="project" label="项目名称"> </el-table-column>
        <el-table-column prop="project_person" label="原始权人" width="270px"> </el-table-column>
        <el-table-column prop="area" label="所在地区" align="center" width="100px"> </el-table-column>
        <el-table-column prop="place" label="发行场所" align="center" width="150px"> </el-table-column>
        <el-table-column align="center" width="86px">
          <template #header>
            <p>发行规模</p>
            <span class="subtitle">(亿元)</span>
          </template>
          <template scope="scope">
            {{scope.row.scale&&Number(scope.row.scale).toFixed(2)}}
          </template>
        </el-table-column>
        <el-table-column prop="release_date" label="发行日期" align="center" width="135px"> </el-table-column>
      </el-table>
      <!-- 移动端显示 -->
      <ul v-else class="market-project">
        <li class="project-title">项目名称</li>
        <li class="project-item justify-between" v-for="(item,index) in tableData" :key="index">
          <span class="text-ellipsis">{{item.project}}</span>
          <el-button type="text" @click="$router.push({path:'/market-details',query:item})">查看</el-button>
        </li>
      </ul>
      <Page v-if="$isPc" :resetPage="resetPage" :count="tableCount" @page="changeTablePage" />
      <el-button v-if="!$isPc && tablePage < tableCount " type="text" icon="el-icon-arrow-down" class="look-more-down"
        @click="moreDown()">
        查看更多</el-button>
    </section>
    <!-- 市场快讯 -->
    <section class="mb-30">
      <div class="ipbs-title">
        <h4>市场快讯</h4>
      </div>
      <List v-if="$isPc" :data="newsData" :count="newsCount" @page="changeNewsData" />
      <LoadMore v-else :onLoadMore="onLoadMore" :enableLoadMore="enableLoadMore">
        <List :data="newsData" :count="newsCount" @page="changeNewsData" />
      </LoadMore>
    </section>
  </div>
</template>
<script>
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
import Page from '@/components/Page.vue'
import DataBlanket from '@/components/DataBlanket.vue'
import { getProjectlist, getArticle } from '../../api/index'
export default {
  name: 'Market',
  components: {
    LoadMore,
    List,
    Page,
    DataBlanket
  },
  data () {
    return {
      searchVal: '', // 搜索内容
      searchValTwo: '', // 搜索内容
      isSearchError: false,//是否展示搜索错误无样式
      isSearch: false,
      resetPage: false,//重置分页
      tableCount: '',// table表格总页数-PC端
      tableData: [],// table表格数据
      tablePage: 1, // table表格页码-移动端
      newsCount: '',// 快讯数据总页数-PC端
      newsData: [],// 快讯数据数据
      page: 1,// 快讯数据页码-移动端
      enableLoadMore: true,
    }
  },
  mounted () {
    this.getTableData(1)
    this.onLoadMore()
  },
  methods: {
    async getTableData (page) {
      try {
        let { data, total } = await getProjectlist(page, this.isSearch ? this.searchValTwo : null)
        this.tableCount = total
        this.tableData = this.$isPc ? data : [...this.tableData, ...data]
      } catch (error) { }
    },
    async getNewsData (page) {
      try {
        let { data, total } = await getArticle({ page, type: 'alerts' })
        this.newsCount = total
        this.newsData = this.$isPc ? data : [...this.newsData, ...data]
      } catch (error) { }

    },
    // 搜索框为空 展示全部
    changeAll () {
      if (!this.searchVal) {
        this.isSearch = false
        this.resetPage = !this.resetPage
        this.tablePage = 1
        this.tableData = []
        this.getTableData(1)
      }
    },
    // 搜索
    searchBtn () {
      if (this.searchVal) {
        this.isSearch = true
        this.resetPage = !this.resetPage
        this.searchValTwo = this.searchVal
        this.tablePage = 1
        this.tableData = []
        this.getTableData(1)
      } else {
        this.isSearchError = true
      }
    },
    // table切换页码
    changeTablePage (page) {
      this.getTableData(page)
    },
    // table查看更多
    async moreDown () {
      this.tablePage += 1
      await this.getTableData(this.tablePage);
      if (this.tablePage >= this.tableCount) {
        return
      }
    },
    // 快讯数据切换页码
    changeNewsData (page) {
      this.getNewsData(page)
    },
    // 上拉加载
    async onLoadMore (done) {
      await this.getNewsData(this.page);
      done && done();
      if (this.page >= this.newsCount) {
        this.enableLoadMore = false
        return
      }
      this.page = this.page + 1
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.market-search {
  padding: 15px 30px;
  margin-bottom: 12px;
  label {
    color: #333333;
    font-weight: bold;
    margin-right: 40px;
  }
  .market-search-inp {
    width: 500px;
    .el-input {
      float: left;
      .el-input__inner {
        background-color: #f3f3f3;
        padding-right: 56px;
      }
    }
    .el-button {
      height: 100%;
      border: none;
      padding-right: 7px;
      background-color: transparent;
      margin-top: -1px;
      .el-icon-search {
        width: 32px;
        color: #2b62b2;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 767px) {
  /deep/.market-search {
    padding: 10px;
    margin-bottom: 0;
  }
  .market-project {
    & > li {
      padding: 20px 10px;
    }
    .project-title {
      background-color: #2b62b2;
      color: #fff;
    }
    .project-item {
      &:nth-child(odd) {
        background-color: #f1f2fa;
      }
      span {
        width: 88%;
        line-height: 18px;
      }
      .el-button {
        text-decoration: underline;
      }
    }
  }
}
</style>